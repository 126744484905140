import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';
import { XMarkIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { doc, deleteDoc } from 'firebase/firestore';
import { db, functions } from '../firebase';
import { toast } from 'react-toastify';
import { httpsCallable } from 'firebase/functions';

function NoteDetail({ note, onClose, onDelete }) {
  const [activeTab, setActiveTab] = useState('note');
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentNote, setCurrentNote] = useState(note); // Local state to track the note

  useEffect(() => {
    setCurrentNote(note); // Update local state when prop changes
  }, [note]);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return format(date, 'PPpp');
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Are you sure you want to permanently delete this note?');
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, 'notes', currentNote.id));
        toast.success('Note deleted successfully');
        onDelete(currentNote.id); // Call the onDelete function passed from the parent
        onClose(); // Close the detail view
      } catch (error) {
        console.error('Error deleting note:', error);
        toast.error('Failed to delete note');
      }
    }
  };

  const handleRetry = async () => {
    setIsProcessing(true);
    try {
      const processTranscript = !currentNote.transcript;
      const processNote = httpsCallable(functions, 'processNote');
      const result = await processNote({
        docId: currentNote.id,
        processTranscript: processTranscript,
        processSummary: true,
        processMetadata: true,
        processSendEmail: true,
        noteData: currentNote, // Assuming noteData is the same as note
      });

      if (result.data && result.data.result === 'Ok') {
        toast.success('Note reprocessed successfully');
        // The updated note will be received via props and reflected in currentNote
      } else {
        toast.error('Failed to reprocess note');
      }
    } catch (error) {
      console.error('Error retrying note processing:', error);
      toast.error('Error retrying note processing');
    } finally {
      setIsProcessing(false);
    }
  };

  if (!currentNote) {
    return null; // If no note is selected, render nothing
  }

  // Determine if the note is in error state
  const isError = currentNote.processingStatus === 400 || currentNote.processingStatus === 402;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative mx-auto p-5 border w-full h-full sm:h-auto sm:top-20 sm:max-w-2xl shadow-lg rounded-md bg-white overflow-y-auto">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <XMarkIcon className="h-6 w-6" />
        </button>

        <div className="text-center mb-6 pt-8 sm:pt-0">
          <p className="text-gray-500 text-sm mb-4">{formatDate(currentNote.creationDate)}</p>

          {/* Emoji based on status */}
          <span
            className={`text-5xl my-6 block ${
              isError ? 'text-red-500' : 'text-blue-500'
            }`}
          >
            {isError ? '😱' : isProcessing ? '💃' : currentNote.emoji}
          </span>

          {/* Title based on status */}
          <h2 className="text-2xl font-bold mb-4">
            {isProcessing
              ? 'Processing note...'
              : isError
              ? 'There was an error processing the note'
              : currentNote.title}
          </h2>

          {/* Tags Section */}
          {!isError && currentNote.tags && currentNote.tags.length > 0 && (
            <div className="flex justify-center flex-wrap gap-2 mb-4">
              {currentNote.tags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded"
                >
                  {tag}
                </span>
              ))}
            </div>
          )}

          {/* Error Message */}
          {isError && !isProcessing && (
            <div className="text-gray-500 p-4 rounded mb-4">
              {currentNote.processingLastError || 'An unknown error occurred.'}
            </div>
          )}

          {/* Retry and Delete Buttons */}
          {isError && !isProcessing && (
            <div className="flex flex-col items-center">
              <button
                onClick={handleRetry}
                className="flex items-center justify-center my-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors mb-4"
              >
                 Retry
              </button>
              {/* Delete Button */}
              <button
                onClick={handleDelete}
                className="px-4 py-2 my-4 bg-gray-100 text-red-500 rounded hover:bg-red-600 transition-colors"
              >
                Delete Note
              </button>
            </div>
          )}
        </div>

        {/* Main Content for Non-Error Notes */}
        {!isError && (
          <>
            <hr className="mb-6" />

            {/* Left-aligned content starts here */}
            <div className="text-left">
              {/* Keywords section */}
              {currentNote.keywords && currentNote.keywords.length > 0 && (
                <div className="mb-4">
                  <h3 className="text-lg font-semibold mb-2">Keywords:</h3>
                  <div className="flex flex-wrap gap-2">
                    {currentNote.keywords.map((keyword, index) => (
                      <span key={index} className="bg-gray-200 text-gray-800 text-sm px-2 py-1 rounded">
                        {keyword}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {/* Insights section */}
              {currentNote.insights && (
                <div className="mb-4 bg-gray-100 rounded-lg p-4">
                  <h3 className="text-lg font-semibold mb-2">Insights ✨</h3>
                  <div className="prose max-w-none">
                    <ReactMarkdown>{currentNote.insights}</ReactMarkdown>
                  </div>
                </div>
              )}

              {/* Summary section */}
              <div className="mb-4 max-w-none">
                <h3 className="text-sm2 font-bold text-gray-500 mb-4">SUMMARY</h3>
                {currentNote.summary_short && currentNote.summary_short.trim() !== '' && (
                  <ReactMarkdown>{currentNote.summary_short}</ReactMarkdown>
                )}
              </div>

              {/* Tabs for Note and Transcript */}
              <div className="mb-4">
                <div className="flex border-b">
                  <button
                    className={`py-2 px-4 ${
                      activeTab === 'note' ? 'border-b-2 border-blue-500' : ''
                    }`}
                    onClick={() => setActiveTab('note')}
                  >
                    Note
                  </button>
                  <button
                    className={`py-2 px-4 ${
                      activeTab === 'transcript' ? 'border-b-2 border-blue-500' : ''
                    }`}
                    onClick={() => setActiveTab('transcript')}
                  >
                    Transcript
                  </button>
                </div>
                <div className="mt-4">
                  {activeTab === 'note' && (
                    <div className="prose max-w-none">
                      <ReactMarkdown>{currentNote.summary}</ReactMarkdown>
                    </div>
                  )}
                  {activeTab === 'transcript' && (
                    <div className="prose max-w-none">
                      <ReactMarkdown>{currentNote.transcript || 'No transcript available.'}</ReactMarkdown>
                    </div>
                  )}
                </div>
              </div>

              {/* Audio player */}
              {currentNote.recordingURL && (
                <div className="mt-8">
                  <h3 className="text-sm2 font-bold text-gray-500">RECORDING</h3>
                  <div className="audio-player">
                    <audio controls src={currentNote.recordingURL} className="w-full">
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                </div>
              )}

              {/* Delete button */}
              <div className="mt-12 pb-8 text-center">
                <button
                  onClick={handleDelete}
                  className="px-4 py-2 bg-gray-200 text-red-500 rounded hover:bg-red-600 transition-colors"
                >
                  Delete Note
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

NoteDetail.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.string.isRequired,
    emoji: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    creationDate: PropTypes.object.isRequired,
    summary: PropTypes.string,
    summary_short: PropTypes.string,
    transcript: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    keywords: PropTypes.arrayOf(PropTypes.string),
    insights: PropTypes.string,
    recordingURL: PropTypes.string,
    processingStatus: PropTypes.number, // Added processingStatus
    processingLastError: PropTypes.string, // Added processingLastError
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default NoteDetail;
