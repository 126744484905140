import React from 'react';
import microImage from '../assets/micro.png'; // Asegúrate de que la ruta sea correcta

function FloatingRecordButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      className="fixed bottom-6 right-6 w-24 h-24 bg-white border-4 border-orange-500 rounded-full shadow-[0_10px_20px_-3px_rgba(0,0,0,0.3),0_4px_6px_-4px_rgba(0,0,0,0.3)] hover:shadow-[0_20px_25px_-5px_rgba(0,0,0,0.3),0_8px_10px_-6px_rgba(0,0,0,0.3)] flex items-center justify-center focus:outline-none transition-shadow duration-300"
      title="Grabar Nota de Voz"
    >
      <img src={microImage} alt="Micrófono" className="w-12 h-12 object-contain" />
    </button>
  );
}

export default FloatingRecordButton;