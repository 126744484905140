// src/components/Login.js

import React, { useState, useEffect } from 'react';
import { signInWithPopup } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { auth, googleProvider, db, storage, functions } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ref, uploadString } from 'firebase/storage';
import notemailLogo from './notemail_logo.png';
import googleLogo from './googleLogo.png';
import { toast } from 'react-toastify';
import { SIGN_IN_PROVIDER_MAP } from '../constants';

function Login() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [lastLoginMethod, setLastLoginMethod] = useState(null);
  const [showAllOptions, setShowAllOptions] = useState(false);
  const [googleUserInfo, setGoogleUserInfo] = useState(null);
  const [showEmailForm, setShowEmailForm] = useState(false);

  useEffect(() => {
    const storedLoginMethod = localStorage.getItem('lastLoginMethod');
    const storedEmail = localStorage.getItem('lastLoginEmail');
    const storedGoogleInfo = JSON.parse(localStorage.getItem('googleUserInfo'));

    if (storedLoginMethod) {
      setLastLoginMethod(storedLoginMethod);
      if (storedLoginMethod === 'email' && storedEmail) {
        setEmail(storedEmail);
      } else if (storedLoginMethod === 'google' && storedGoogleInfo) {
        setGoogleUserInfo(storedGoogleInfo);
      }
    }
  }, []);

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      await handleUserSignIn(result.user);
      localStorage.setItem('lastLoginMethod', 'google');
      localStorage.setItem('googleUserInfo', JSON.stringify({
        email: result.user.email,
        displayName: result.user.displayName,
        photoURL: result.user.photoURL
      }));
      // Successful login
    } catch (error) {
      console.error("Error logging in with Google:", error);
      setMessage('Failed to sign in with Google. Please try again.');
    }
  };

  // Función personalizada para enviar el enlace de inicio de sesión
  const sendSignInLinkCustom = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage('Please enter a valid email address.');
      return;
    }

    // Detectar si está en entorno local
    const isLocal = process.env.NODE_ENV === 'development';

    // Definir la URL de redirección basada en el entorno
    const finishSignInUrl = isLocal
      ? 'http://localhost:3000/finishSignIn' // Asegúrate de que el puerto coincida con tu configuración local
      : 'https://app.notemail.ai/finishSignIn'; // Reemplaza con tu dominio de producción

    // Construir los parámetros para la función personalizada
    const functionParams = {
      email: email,
      appInterfaceLanguageLocale: navigator.language || 'EN',
      platform: 'web', // Indica que la solicitud proviene de la web
    };

    // Si está en entorno local, agregar el parámetro 'url'
    if (isLocal) {
      functionParams.url = finishSignInUrl;
    }

    // Llamar a la función personalizada de Firebase
    const sendSignInLinkFunction = httpsCallable(functions, 'sendSignInLink');

    try {
      setMessage('Sending sign-in link...');
      const result = await sendSignInLinkFunction(functionParams);

      if (result.data) {
        setMessage('Sign-in link sent successfully. Check your email.');
        window.localStorage.setItem('emailForSignIn', email);
        localStorage.setItem('lastLoginMethod', 'email');
        localStorage.setItem('lastLoginEmail', email);
        setShowEmailForm(true);
      } else {
        throw new Error(result.data.message || 'Unknown error occurred while sending the link.');
      }
    } catch (error) {
      console.error("Error sending sign-in link:", error);
      setMessage('Failed to send sign-in link. Please try again.');
    }
  };

  const handleUserSignIn = async (user) => {
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) {
      // Mapear el providerId a nuestro enum
      let providerNumber;
      switch (user.providerData[0].providerId) {
        case 'google.com':
          providerNumber = 1; // Google
          break;
        case 'apple.com':
          providerNumber = 2; // Apple
          break;
        default:
          providerNumber = 0; // Email u otros
      }

      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,
        displayName: user.displayName,
        sendEmailAddress: user.email,
        signInProvider: providerNumber,
        userLocale: navigator.language || 'en',
        appInterfaceLanguageLocale: navigator.language || 'en',
        hintKeywords: [],
        aboutMe: '',
      });

      if (user.photoURL) {
        try {
          const response = await fetch(user.photoURL);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64data = reader.result;
            const storageRefPath = ref(storage, `userPictures/${user.uid}.jpg`);
            await uploadString(storageRefPath, base64data, 'data_url');
          };
          reader.readAsDataURL(blob);
        } catch (error) {
          console.error("Error saving user profile picture:", error);
        }
      }
    }
  };

  const renderLoginOptions = () => {
    if (showEmailForm) {
      return renderEmailForm();
    }

    if (!lastLoginMethod || showAllOptions) {
      return (
        <>
          {renderEmailForm()}
          {renderGoogleButton()}
        </>
      );
    } else if (lastLoginMethod === 'email') {
      return (
        <>
          {renderEmailForm()}
          <button
            onClick={() => setShowAllOptions(true)}
            className="mt-4 text-sm text-blue-500 hover:text-blue-700"
          >
            Show more login options
          </button>
        </>
      );
    } else if (lastLoginMethod === 'google') {
      return (
        <>
          {renderGoogleUserInfo()}
          {renderGoogleButton()}
          <button
            onClick={() => setShowAllOptions(true)}
            className="mt-4 text-sm text-blue-500 hover:text-blue-700"
          >
            Show more login options
          </button>
        </>
      );
    }
  };

  const renderEmailForm = () => (
    <form onSubmit={sendSignInLinkCustom} className="w-full max-w-sm mb-4">
      <div className="flex items-center border-b border-gray-500 py-2">
        <input 
          className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
          type="email" 
          placeholder="Enter your email" 
          aria-label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button 
          className="flex-shrink-0 bg-blue-500 hover:bg-blue-700 border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded" 
          type="submit"
        >
          Sign In with Email
        </button>
      </div>
    </form>
  );

  const renderGoogleButton = () => (
    <button
      onClick={signInWithGoogle}
      className="flex items-center bg-white border border-gray-300 rounded-lg shadow-md px-4 py-2 hover:shadow-lg hover:bg-gray-100 transition duration-300"
    >
      <img src={googleLogo} alt="Google Logo" className="w-6 h-6 mr-3" />
      <span className="text-gray-700 font-semibold">Sign in with Google</span>
    </button>
  );

  const renderGoogleUserInfo = () => (
    <div className="mb-4 text-center">
      <img src={googleUserInfo.photoURL} alt="User" className="w-16 h-16 rounded-full mx-auto mb-2" />
      <p className="text-gray-700">{googleUserInfo.displayName}</p>
      <p className="text-gray-500 text-sm">{googleUserInfo.email}</p>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
      <img src={notemailLogo} alt="NoteMail Logo" className="h-16 mb-8" />
      <h1 className="text-2xl font-bold text-gray-700 mb-6">Welcome to NoteMail</h1>
      
      {renderLoginOptions()}

      {message && <p className="mt-4 text-sm text-gray-600">{message}</p>}
      
      {showEmailForm && (
        <button
          onClick={() => {
            setShowEmailForm(false);
            setShowAllOptions(true);
          }}
          className="mt-4 text-sm text-blue-500 hover:text-blue-700"
        >
          Show more login options
        </button>
      )}
    </div>
  );
}

export default Login;