// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; 

const firebaseConfig = {
    apiKey: "AIzaSyBCm48UB_o3mcncVj-kHmdVR1zUKESAsZM",
    authDomain: "email2me-52ae4.firebaseapp.com",
    projectId: "email2me-52ae4",
    storageBucket: "email2me-52ae4.appspot.com",
    messagingSenderId: "1040349118044",
    appId: "1:1040349118044:web:12623034b6f46a60a67099",
    measurementId: "G-D5LKJ1QB8Z"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app); 
const functions = getFunctions(app);
const googleProvider = new GoogleAuthProvider(); 

export { db, storage, auth, googleProvider, functions };