import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

let initialized = false;

export async function initializeAudioRecorder() {
  if (!initialized) {
    await register(await connect());
    initialized = true;
  }
}