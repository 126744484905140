// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { auth, storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import logo from './notemail_logo.png';
import PropTypes from 'prop-types';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'; // Importar iconos de Heroicons v2

function Header({ user }) {
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para el menú móvil
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    if (user) {
      getDownloadURL(ref(storage, `userPictures/${user.uid}.jpg`))
        .then(setProfilePicUrl)
        .catch(console.error);
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [user]);

  const UserAvatar = () => {
    if (profilePicUrl) {
      return <img src={profilePicUrl} alt="User" className="w-10 h-10 rounded-full" />;
    } else {
      const initials = user.displayName ? user.displayName.charAt(0).toUpperCase() : '?';
      return (
        <div className="w-10 h-10 rounded-full bg-purple-500 flex items-center justify-center text-white text-lg font-bold">
          {initials}
        </div>
      );
    }
  };

  // Definir los enlaces de navegación
  const navigationLinks = [
    { name: 'Notes', path: '/notes' },
    // Puedes añadir más enlaces aquí en el futuro
    // { name: 'Another Link', path: '/another' },
  ];

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isScrolled ? 'bg-white bg-opacity-70 backdrop-blur-md shadow-md' : 'bg-white'
    }`}>
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo and Notes link section */}
          <div className="flex items-center space-x-6">
            <div className="flex-shrink-0">
              <Link to="/profile">
                <img src={logo} alt="NoteMail Logo" className="h-10 w-auto" />
              </Link>
            </div>
            {!isMobile && (
              <Link
                to="/notes"
                className="text-gray-500 hover:bg-gray-200 hover:text-gray-900 px-2 py-1 rounded-md text-m font-bold ml-8"
              >
                Notes
              </Link>
            )}
          </div>

          {/* Mobile menu button */}
          {isMobile && (
            <div className="flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                aria-expanded={isMenuOpen}
                aria-label="Toggle navigation menu"
              >
                {isMenuOpen ? (
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          )}

          {/* Desktop user menu */}
          {!isMobile && (
            <div className="flex items-center">
              <Menu as="div" className="relative">
                <div>
                  <Menu.Button className="flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <UserAvatar />
                    <svg
                      className="w-4 h-4 ml-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                  </Menu.Button>
                </div>
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/profile"
                          className={`${
                            active ? 'bg-gray-100' : ''
                          } block px-4 py-2 text-sm text-gray-700`}
                        >
                          View Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => auth.signOut()}
                          className={`${
                            active ? 'bg-gray-100' : ''
                          } block w-full text-left px-4 py-2 text-sm text-gray-700`}
                        >
                          Log out
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Menu>
            </div>
          )}
        </div>

        {isMobile && isMenuOpen && (
          <div className="md:hidden mt-2">
            <div className="bg-white shadow-md rounded-lg p-4">
              {/* Encabezado del Menú Móvil: Foto de perfil, nombre y View Profile */}
              <div className="flex space-x-3 mb-4">
                <div className="flex-shrink-0">
                  <UserAvatar />
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-gray-800">{user.displayName || 'Usuario'}</span>
                  <Link
                    to="/profile"
                    onClick={() => setIsMenuOpen(false)}
                    className="text-blue-600 hover:text-blue-800 text-sm font-medium mt-1"
                  >
                    View Profile
                  </Link>
                </div>
              </div>

              {/* Separador */}
              <hr className="my-4 border-gray-300" />

              {/* Enlace Notes */}
              <Link
                to="/notes"
                onClick={() => setIsMenuOpen(false)}
                className="block w-full text-left text-gray-700 hover:bg-gray-200 hover:text-gray-900 px-3 py-2 rounded-md text-base font-medium font-bold"
              >
                Notes
              </Link>

              {/* Botón de Cerrar Sesión */}
              <button
                onClick={() => {
                  auth.signOut();
                  setIsMenuOpen(false);
                }}
                className="w-full text-left text-gray-700 hover:bg-gray-200 hover:text-gray-900 px-3 py-2 rounded-md text-base font-medium"
              >
                Log out
              </button>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

// Validación de props
Header.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Header;
