// src/components/Notes.js
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { auth, db } from '../firebase';
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  limit,
  startAfter,
  getDocs,
} from 'firebase/firestore';
import NoteCard from './NoteCard'; // Importa el componente NoteCard
import { toast } from 'react-toastify'; // Para notificaciones
import {
  format,
  isToday,
  isYesterday,
  isThisWeek,
  isThisMonth,
  isThisYear,
  subMonths,
  isSameMonth,
} from 'date-fns';
import NoteDetail from './NoteDetail';
import { PAGE_SIZE } from '../constants'; // Importar PAGE_SIZE correctamente
import { trackNotesLoaded, trackNoteOpened, trackNoteDeleted } from '../analytics';

function Notes() {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNoteId, setSelectedNoteId] = useState(null); // Changed from selectedNote to selectedNoteId
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const notesRef = useMemo(() => collection(db, 'notes'), [db]);
  const unsubscribeRef = useRef([]); // Para gestionar múltiples listeners
  const isFetchingRef = useRef(false); // Para evitar múltiples fetch simultáneos

  useEffect(() => {
    console.log('useEffect triggered');

    if (!auth.currentUser) {
      setNotes([]);
      setLoading(false);
      setHasMore(false);
      return;
    }

    const qInitial = query(
      notesRef,
      where('uid', '==', auth.currentUser.uid),
      where('isPendingtoDelete', '==', false), 
      orderBy('creationDate', 'desc'),
      limit(PAGE_SIZE)
    );

    trackNotesLoaded(0);

    const unsubscribe = onSnapshot(qInitial, (snapshot) => {
      console.log("Snapshot received");
      let hasChanges = false;

      snapshot.docChanges().forEach((change) => {
        console.log(`snapshot.docChanges: ${change.type} id: ${change.doc.id}`);

        if (change.type === "added" || change.type === "modified") {
          const noteData = change.doc.data();
          if (!noteData.isPendingtoDelete) {
            setNotes((prevNotes) => {
              const index = prevNotes.findIndex(note => note.id === change.doc.id);
              if (index !== -1) {
                // Update existing note
                const updatedNotes = [...prevNotes];
                updatedNotes[index] = { id: change.doc.id, ...noteData };
                return updatedNotes;
              } else {
                // Add new note
                return [...prevNotes, { id: change.doc.id, ...noteData }];
              }
            });
            hasChanges = true;
          }
        } else if (change.type === "removed" || (change.type === "modified" && change.doc.data().isPendingtoDelete)) {
          console.log('Note marked as pending delete:', change.doc.id);
          setNotes((prevNotes) => prevNotes.filter((note) => note.id !== change.doc.id));
          hasChanges = true;
        }
      });

      if (hasChanges) {
        setNotes((prevNotes) => {
          const sortedNotes = [...prevNotes].sort((a, b) => b.creationDate.toDate() - a.creationDate.toDate());
          return sortedNotes;
        });
      }

      setLastDoc(snapshot.docs[snapshot.docs.length - 1]);
      setLoading(false);
    });

    // Guardar el unsubscribe para limpiar después
    unsubscribeRef.current.push(unsubscribe);

    return () => {
      console.log('Cleaning up listeners');
      // Limpiar todos los listeners al desmontar el componente
      unsubscribeRef.current.forEach((unsubscribe) => unsubscribe());
      unsubscribeRef.current = []; 
    };
  }, [notesRef]);

  // Función para obtener más notas utilizando getDocs para la paginación
  const fetchMoreNotes = useCallback(async () => {
    if (!auth.currentUser) {
      setHasMore(false);
      return;
    }

    if (!lastDoc || loadingMore || isFetchingRef.current) return;

    isFetchingRef.current = true;
    setLoadingMore(true);

    const qMore = query(
      notesRef,
      where('uid', '==', auth.currentUser.uid), 
      where('isPendingtoDelete', '==', false),
      orderBy('creationDate', 'desc'),
      startAfter(lastDoc),
      limit(PAGE_SIZE)
    );

    try {
      console.log('Fetching more notes');
      const querySnapshot = await getDocs(qMore);
      const newNotes = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setNotes((prevNotes) => {
        const notesMap = new Map();
        prevNotes.forEach(note => notesMap.set(note.id, note));
        newNotes.forEach(note => notesMap.set(note.id, note));
        return Array.from(notesMap.values()).sort(
          (a, b) => b.creationDate.toDate() - a.creationDate.toDate()
        );
      });

      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastDoc(lastVisible || null);
      setHasMore(querySnapshot.size === PAGE_SIZE);
    } catch (error) {
      console.error('Error fetching more notes:', error);
      toast.error('Failed to load more notes.');
    } finally {
      setLoadingMore(false);
      isFetchingRef.current = false;
    }
  }, [lastDoc, notesRef, hasMore, loadingMore]);

  // Función para alternar el estado de completado de un TODO
  const toggleTodoCompletion = async (noteId, currentStatus) => {
    console.log('toggleTodoCompletion called with noteId:', noteId, 'and currentStatus:', currentStatus);
    // Actualizar el estado local optimísticamente
    setNotes((prevNotes) =>
      prevNotes.map((note) =>
        note.id === noteId
          ? { ...note, isTodoCompleted: !currentStatus }
          : note
      )
    );

    try {
      console.log('Updating todo status in Firestore');
      const noteRef = doc(db, 'notes', noteId);
      await updateDoc(noteRef, {
        isTodoCompleted: !currentStatus,
      });
      toast.success(
        `Task marked as ${!currentStatus ? 'completed' : 'incomplete'}!`
      );
    } catch (error) {
      console.error('Error updating todo status:', error);
      // Revertir el estado local
      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.id === noteId
            ? { ...note, isTodoCompleted: currentStatus }
            : note
        )
      );
      toast.error('Failed to update task status.');
    }
  };

  const groupNotes = (notes) => {
    const groups = {
      Today: [],
      Yesterday: [],
      'This week': [],
      'This month': [],
      'Last month': [],
      'This year': [],
    };

    const now = new Date();
    const lastMonth = subMonths(now, 1);

    notes.forEach((note) => {
      const date = note.creationDate.toDate();
      if (isToday(date)) groups['Today'].push(note);
      else if (isYesterday(date)) groups['Yesterday'].push(note);
      else if (isThisWeek(date)) groups['This week'].push(note);
      else if (isThisMonth(date)) groups['This month'].push(note);
      else if (isSameMonth(date, lastMonth)) groups['Last month'].push(note);
      else if (isThisYear(date)) groups['This year'].push(note);
      else {
        const year = format(date, 'yyyy');
        if (!groups[year]) groups[year] = [];
        groups[year].push(note);
      }
    });

    return groups;
  };

  const openNoteDetail = (noteId) => { // Changed to accept noteId
    setSelectedNoteId(noteId);
    trackNoteOpened(noteId);
  };

  const closeNoteDetail = () => {
    setSelectedNoteId(null);
  };

  const handleNoteDelete = (deletedNoteId) => {
    setNotes((prevNotes) => prevNotes.filter((note) => note.id !== deletedNoteId));
    trackNoteDeleted(deletedNoteId);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-gray-500">Loading notes...</div>
      </div>
    );
  }

  if (notes.length === 0) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-gray-500">You have no notes yet.</div>
      </div>
    );
  }

  const groupedNotes = groupNotes(notes);

  // Find the selected note from the notes array based on selectedNoteId
  const selectedNote = selectedNoteId ? notes.find(note => note.id === selectedNoteId) : null;

  return (
    <div className="p-4">
      {Object.entries(groupedNotes).map(([groupName, groupNotes]) => (
        groupNotes.length > 0 && (
          <div key={groupName} className="mb-8">
            <h2 className="text-2xl font-bold mb-4">{groupName}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {groupNotes.map((note) => (
                <div
                  key={note.id}
                  onClick={() => openNoteDetail(note.id)} // Pass note.id instead of note object
                  className="cursor-pointer"
                >
                  <NoteCard
                    note={note}
                    toggleCompletion={toggleTodoCompletion}
                  />
                </div>
              ))}
            </div>
          </div>
        )
      ))}

      {hasMore && (
        <div className="flex items-center justify-center my-4">
          <button
            onClick={fetchMoreNotes}
            disabled={loadingMore}
            className="px-3 py-2 m-4 bg-gray-200 text-gray-700 text-sm rounded hover:bg-gray-400 disabled:bg-gray-200 disabled:text-gray-500"
          >
            {loadingMore ? 'Loading...' : 'Load more notes'}
          </button>
        </div>
      )}

      {selectedNote && (
        <NoteDetail 
          note={selectedNote} 
          onClose={closeNoteDetail} 
          onDelete={handleNoteDelete}
        />
      )}
    </div>
  );
}

export default Notes;
