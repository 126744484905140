// src/components/NoteCard.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { format } from 'date-fns';
import { CheckIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types'; // For prop type checking

// Full processing status map with English descriptions
const processingStatusMap = {
  0: "Not started",
  5: "Uploading audio",
  10: "Transcribing",
  21: "Summarizing",
  22: "Extracting metadata",
  30: "Sending email",
  200: "Completed",
  400: "Processing error",
  401: "Offline",
  402: "No audio detected",
};

function NoteCard({ note, toggleCompletion }) {
  // Function to format Firestore timestamp
  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate(); // Convert Firestore Timestamp to JS Date
    return format(date, 'PPpp'); // e.g., Jan 1, 2023, 12:00 PM
  };

  const isProcessing = note.processingStatus > 0 && note.processingStatus < 200;

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <div className="flex items-center mb-2">
        {/* Task Completion Indicator for Todos */}
        {note.noteType === 0 && (
          <div
            className="mr-2 flex-shrink-0 cursor-pointer"
            onClick={() => toggleCompletion(note.id, note.isTodoCompleted)}
            title={note.isTodoCompleted ? 'Mark as Incomplete' : 'Mark as Complete'}
            aria-label={note.isTodoCompleted ? 'Mark as Incomplete' : 'Mark as Complete'}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') toggleCompletion(note.id, note.isTodoCompleted);
            }}
          >
            {note.isTodoCompleted ? (
              <div className="w-5 h-5 flex items-center justify-center bg-green-500 text-white rounded-full border-2 border-green-500">
                <CheckIcon className="w-3 h-3" />
              </div>
            ) : (
              <span className="w-5 h-5 inline-block border-2 border-gray-400 rounded-full"></span>
            )}
          </div>
        )}
        {/* Emoji and Title */}
        <span className="text-2xl mr-2">{note.emoji}</span>
        <h2 className="text-xl font-bold">{note.title}</h2>
      </div>
      {/* Creation Date */}
      <p className="text-gray-500 text-sm mb-2">{formatDate(note.creationDate)}</p>
      
      {isProcessing ? (
        <div>
          <p className="text-blue-600 font-semibold mb-2">
            {processingStatusMap[note.processingStatus]}
          </p>
          <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 bg-gray-200">
            <div 
              className="bg-gray-600 h-2.5 rounded-full" 
              style={{ width: `${note.processingProgress}%` }}
            ></div>
          </div>
        </div>
      ) : (
        <>
          <div className="prose prose-sm line-clamp-3 mb-4">
            <ReactMarkdown>{note.summary_short || note.summary}</ReactMarkdown>
          </div>
          <div className="flex flex-wrap gap-2">
            {note.tags?.map((tag, index) => (
              <span key={index} className="bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">
                {tag}
              </span>
            ))}
          </div>
        </>
      )}
      
      {/* Display error status if applicable */}
      {(note.processingStatus === 400 || note.processingStatus === 402) && (
        <div className="mt-2 inline-block px-2 py-1 text-xs font-semibold text-red-800 bg-red-100 rounded">
          {processingStatusMap[note.processingStatus]}
        </div>
      )}
    </div>
  );
}

// Update propTypes validation
NoteCard.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.string.isRequired,
    emoji: PropTypes.string,
    title: PropTypes.string.isRequired,
    creationDate: PropTypes.object.isRequired, // Firestore Timestamp
    summary: PropTypes.string,
    summary_short: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    noteType: PropTypes.number.isRequired,
    isTodoCompleted: PropTypes.bool,
    isProcessing: PropTypes.bool,
    processingStatus: PropTypes.number,
    processingProgress: PropTypes.number,
  }).isRequired,
  toggleCompletion: PropTypes.func.isRequired,
};

export default NoteCard;
