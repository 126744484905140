import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN } from './constants';

// Initialize Mixpanel
mixpanel.init(MIXPANEL_TOKEN);

// Identify user
export const identifyUser = (userId, userProperties) => {
  mixpanel.identify(userId);
  mixpanel.people.set(userProperties);
};

// Mixpanel event tracking functions
export const trackEvent = (eventName, properties = {}) => {
  mixpanel.track(eventName, properties);
};

// Event tracking functions
export const trackNotesLoaded = (noteCount) => {
  trackEvent('notes_loaded', { noteCount });
};

export const trackNoteOpened = (noteId) => {
  trackEvent('note_opened', { noteId });
};

export const trackNoteDeleted = (noteId) => {
  trackEvent('note_deleted', { noteId });
};

export const trackNoteCreated = (noteId, isManual, recordingDuration, transcriptWords, summaryWords) => {
  trackEvent('note_created', {
    noteId,
    isManual,
    recordingDuration,
    transcriptWords,
    summaryWords
  });
};

export const trackLogin = (method, uid) => {
  trackEvent('login', { method, uid });
};

export const trackSignUp = (method, uid) => {
  trackEvent('sign_up', { method, uid });
};

// New function for Firebase note_created event
export const trackFirebaseNoteCreated = (noteId, recordingDuration) => {
  trackEvent('note_created', {
    noteId,
    recordingDuration
  });
};

// Add more tracking functions as needed...
